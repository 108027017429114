.brandportal {
  @media (--breakpoint-up-sm) {
    .cmp-loginteaser {
      display: flex;
      justify-content: center;
      gap: var(--spacing-lg);
    }
  }

  @media only screen and (--breakpoint-down-sm) {
    .cmp-loginteaser__placeholder {
      margin: 0 auto var(--spacing-sm);
    }
  }

  .cmp-loginteaser__placeholder {
    height: 22.5rem;
    width: 17.625rem;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0.063rem 0.188rem rgb(0 0 0 / 40%);
  }

  .cmp-loginteaser__placeholder:hover {
    box-shadow: 0 0.125rem 0.75rem -0.125rem rgb(0 0 0 / 40%);
  }

  .cmp-loginteaser__placeholder-content {
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    padding: var(--spacing-lg);
    height: 100%;
    justify-content: flex-end;
  }

  .cmp-loginteaser__title {
    @include bold-xl();

    z-index: 1;
    margin-bottom: var(--spacing-sm);
  }

  .cmp-loginteaser__description {
    @include regular-md();

    z-index: 1;
    margin-bottom: var(--spacing-sm);
    display: flex;
  }

  .cmp-loginteaser__link {
    font-weight: var(--font-weight-bold);
    z-index: 1;
    display: flex;
    justify-content: space-between;

    .cmp-loginteaser__link-icon {
      animation: link-icon-hover-reverse 0.15s ease-in-out 0s 1 forwards;
    }

    .icon-keyboard_arrow_right {
      animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;
    }

    &:hover {
      .cmp-loginteaser__link-icon {
        animation: link-icon-hover 0.15s ease-in-out 0s 1 forwards;
      }

      .icon-keyboard_arrow_right {
        animation: link-icon-right 0.15s ease-in-out 0s 1 forwards;
      }

      .cmp-loginteaser__link-text {
        text-decoration: underline;
      }
    }
  }

  .cmp-loginteaser__placeholder-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .cmp-loginteaser__placeholder-image::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(0deg, rgb(0 0 0 / 35%), rgb(0 0 0 / 35%));
  }
}
