/* stylelint-disable selector-max-compound-selectors */

@import '../../../site/styles/settings/mixins.scss';

@include remove-grid-content('.cmp-search-results');

.brandportal .cmp-search-results {
  .cmp-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(toRem(268px), 1fr));
    grid-gap: toRem(24px);

    .cmp-card {
      box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
      border: none;
      /* stylelint-disable-next-line declaration-no-important */
      margin: 0 !important;
      width: auto;

      &__title {
        @include bold-lg();

        color: var(--color-black);
        display: flex;

        div {
          width: 100%;
        }
      }

      &__description {
        padding-top: toRem(24px);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &__meta {
        display: flex;
        justify-content: space-between;

        &-item {
          display: flex;
          flex-flow: column;
          min-width: 30%;

          &-label {
            font-size: toRem(12px);
            line-height: toRem(16px);
          }

          &-value {
            @include bold-md();
          }
        }
      }

      &__actions {
        padding: 34px 0 14px;
        justify-content: flex-start;
        gap: var(--spacing-md);
        list-style: none;
        display: flex;
        margin: 0;

        li {
          .cmp-button {
            color: var(--color-black);

            &:hover {
              color: var(--color-primary-500);
            }
          }
        }
      }

      img {
        max-height: 175px;
      }

      /* stylelint-disable-next-line selector-no-qualifying-type */
      img[src*='eps.renditions'] {
        object-fit: none;
      }

      /* stylelint-disable-next-line selector-no-qualifying-type */
      a.image {
        height: 175px;
        display: flex;
        align-items: center;
        /* stylelint-disable-next-line declaration-no-important, selector-no-qualifying-type */
        .cmp-image--card {
          /* stylelint-disable-next-line declaration-no-important */
          height: auto !important;
        }
      }
    }
  }

  .cmp-search-results--load-more {
    @include bold-lg();

    border: var(--spacing-xxxs) solid var(--color-primary-500);
    color: var(--color-primary-500);
    padding: var(--spacing-xs) var(--spacing-lg);
    transition: transform 0.1s;
    display: flex;
    align-items: center;
    margin: var(--spacing-lg) auto;

    .cmp-button__icon {
      @include transition-transform;
    }

    &:hover {
      border-color: var(--color-primary-700);
      box-shadow: 0 0 0 var(--spacing-xxxs) var(--color-primary-700);
      color: var(--color-primary-700);

      .cmp-button__icon {
        @include link-icon-down;
      }
    }
  }

  .cmp-list {
    @include regular-md();

    &__head {
      border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);
      color: var(--color-gray-700);

      .cmp-list__row {
        &-cell {
          text-transform: uppercase;
          font-weight: var(--font-weight);

          &:first-child {
            width: toRem(120px);
          }

          &:last-child {
            width: toRem(90px);
          }

          &.description {
            width: toRem(250px);
          }
        }
      }
    }

    &__row {
      border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);

      &-cell {
        padding: var(--spacing-md) 0;
        vertical-align: middle;

        & a:not(.cmp-list__row-cell-title) {
          display: flex;
          align-items: center;
          width: toRem(100px);
          height: toRem(100px);
          overflow: hidden;
        }

        &-image {
          width: 100%;
        }

        &-title {
          color: var(--color-primary-500);
          text-decoration: underline;

          &:hover {
            color: var(--color-primary-700);
          }
        }

        &__description {
          vertical-align: middle;
          font-weight: var(--font-weight);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: toRem(20px);
        }

        .cmp-button {
          color: var(--color-black);

          &:hover {
            color: var(--color-primary-700);
          }
        }
      }

      @media (--breakpoint-down-sm) {
        display: flex;
        flex-flow: column;
        padding: var(--spacing-xs) 0;

        &-cell {
          padding: toRem(4px) 0;

          .cmp_card__action-buttons {
            justify-content: flex-start;
            gap: var(--spacing-md);
          }
        }
      }
    }
  }

  .cmp-search-results-no-assets-found {
    border-width: toRem(1px) toRem(1px) toRem(1px) toRem(5px);
    border-style: solid;
    border-color: var(--color-error-500);
    padding: var(--spacing-xl) var(--spacing-xxxl) var(--spacing-md);

    &__icon {
      color: var(--color-error-500);
      font-size: var(--font-size-icon-lg);
      padding-bottom: var(--spacing-lg);
    }

    &__message p {
      margin-bottom: var(--spacing-xs);
    }
  }
}
